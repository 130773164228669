import React from 'react';
import { useTranslation } from 'react-i18next';
import DefaultLayout from 'components/layout/DefaultLayout';
import { FullScreenError, FullScreenSpinner } from 'components/FullScreen';
import { useGetEvaluator, usePostEvaluator } from 'api/evaluator.hooks';
import Questions from 'components/Questions';
import {
  Answers,
  Evaluator,
  ExploreProfileResponse,
  Question,
} from 'types/evaluator';
import { styled } from '@mui/material';
import { evaluatorTitleKey } from 'pages/Evaluator/evaluatorTitleKey';
import { UserInfoForm } from './AboutYou';

const StyledMain = styled('main')({
  width: '100%',
  maxWidth: '845px',
});
const mapToRequestFormat = (
  questionsData: Question[],
  answersData: Answers,
  seed: number,
  {
    fullName,
    knownAs,
    pronoun,
    preferredDialect,
    countryOfResidence,
    age,
    jobTitle,
    lengthOfService,
    jobLevel,
    sector,
    subSector,
    referralCode,
  }: UserInfoForm,
  dialect: string,
) => {
  const answers = questionsData.reduce<{
    frames: {
      propositions: {
        key: string;
        rank: number;
      }[];
    }[];
    before?: {
      key: string;
      answer: string;
      type: string;
    }[];
    after?: {
      key: string;
      answer: string;
      type: string;
    }[];
  }>(
    (acc, question) => {
      switch (question.type) {
        case 'FRAME': {
          return {
            ...acc,
            frames: [
              ...acc.frames,
              {
                propositions: question.propositions.map((proposition) => ({
                  key: proposition.key,
                  rank: answersData.frames[proposition.key],
                })),
              },
            ],
          };
        }
        case 'CHOICE': {
          return {
            ...acc,
            before: [
              ...(acc.before || []),
              {
                key: question.key,
                answer: answersData.choice[question.key],
                type: question.type,
              },
            ],
          };
        }
        case 'OPEN': {
          return {
            ...acc,
            after: [
              ...(acc.after || []),
              {
                key: question.key,
                answer: answersData.open[question.key],
                type: question.type,
              },
            ],
          };
        }

        default:
          throw new Error('Unexpected frame type');
      }
    },
    { frames: [] },
  );

  const userInfo = { fullName, knownAs, pronoun, preferredDialect };

  const demographicData = {
    jobTitle,
    countryOfResidenceKey: countryOfResidence,
    ageKey: age,
    lengthOfServiceKey: lengthOfService,
    levelOfPositionKey: jobLevel,
    industrySectorKey: sector,
    industrySubSectorKey: subSector,
  };

  return {
    demographicData,
    ...answers,
    seed,
    userInfo,
    ...(referralCode && {
      referralCode,
    }),
    dialect,
  };
};

type EvaluatorContentProps = {
  evaluatorId: string;
  userInfo: UserInfoForm;
  evaluatorModel: Evaluator;
  token?: string;
  onComplete: () => void;
  subjectFullName?: string;
  responseMutation?: (value: ExploreProfileResponse) => void;
};

const EvaluatorContent = ({
  evaluatorId,
  userInfo,
  evaluatorModel,
  token,
  onComplete,
  subjectFullName,
  responseMutation,
}: EvaluatorContentProps) => {
  const { t, i18n } = useTranslation();

  const { status: getEvaluatorStatus, data } = useGetEvaluator(
    evaluatorModel,
    i18n.language,
  );

  const { mutate, status: postEvaluatorStatus } = usePostEvaluator();

  const handlePostEvaluatorSuccess = async (response: any) => {
    if (evaluatorModel === 'EXPLORE' && responseMutation) {
      const responseData = await response.json(); // Wait for the response data
      responseMutation(responseData); // Update mutateResponse in the Evaluator.tsx file
      onComplete(); // Redirect to the Thanks page
    }
    onComplete();
  };

  const isError =
    getEvaluatorStatus === 'error' || postEvaluatorStatus === 'error';

  const isLoading =
    getEvaluatorStatus === 'loading' || postEvaluatorStatus === 'loading';

  let content;
  if (isError) {
    content = (
      <FullScreenError
        heading={t('ui.io.error.something_wrong')}
        message={t('ui.error.something_wrong_longer_explanation')}
      />
    );
  } else if (isLoading) {
    content = <FullScreenSpinner message={t('ui.io.loading')} />;
  } else if (getEvaluatorStatus === 'success' && data) {
    const { questions, seed } = data;

    content = (
      <StyledMain>
        <Questions
          questions={questions}
          subjectFullName={subjectFullName}
          evaluatorModel={evaluatorModel}
          onEvaluatorSubmit={(answers) =>
            mutate(
              {
                model: evaluatorModel,
                evaluatorId,
                data: mapToRequestFormat(
                  questions,
                  answers,
                  seed,
                  userInfo,
                  i18n.language,
                ),
                token,
              },
              {
                onSuccess: handlePostEvaluatorSuccess,
              },
            )
          }
        />
      </StyledMain>
    );
  }

  return (
    <DefaultLayout
      title={t(evaluatorTitleKey(evaluatorModel))}
      model={evaluatorModel}
    >
      {content}
    </DefaultLayout>
  );
};

export default EvaluatorContent;
