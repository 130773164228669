import {
  Colour,
  ColourEnergy,
  ColourMapProp,
  DatadogRumConfigs,
  IndustrySectors,
  PageTitle,
} from 'types/constants';
import { Evaluator } from 'types/evaluator';

export const PROPOSITION_RANKS = [
  'L_SHORT',
  '1',
  '2',
  '3',
  '4',
  '5',
  'M_SHORT',
] as const;

type PropositionRankType = (typeof PROPOSITION_RANKS)[number];

export const PROPOSITION_RANK_ARIA_LABELS: Record<PropositionRankType, string> =
  {
    L_SHORT: 'ui.io.evaluator.proposition.heading_l',
    '1': '1',
    '2': '2',
    '3': '3',
    '4': '4',
    '5': '5',
    M_SHORT: 'ui.io.evaluator.proposition.heading_m',
  };

export const COUNTRIES: string[] = [
  'ui.io.demographics.country.rather_not_say',
  'ui.common.country.albania',
  'ui.common.country.algeria',
  'ui.common.country.argentina',
  'ui.common.country.australia',
  'ui.common.country.austria',
  'ui.common.country.azerbaijan',
  'ui.common.country.bahrain',
  'ui.common.country.belarus',
  'ui.common.country.belgium',
  'ui.common.country.belize',
  'ui.common.country.bolivia',
  'ui.common.country.bosnia-and-herzegovina',
  'ui.common.country.brazil',
  'ui.common.country.brunei-darussalam',
  'ui.common.country.bulgaria',
  'ui.common.country.canada',
  'ui.common.country.chile',
  'ui.common.country.china',
  'ui.common.country.colombia',
  'ui.common.country.costa-rica',
  'ui.common.country.croatia',
  'ui.common.country.czech-republic',
  'ui.common.country.denmark',
  'ui.common.country.dominican-republic',
  'ui.common.country.ecuador',
  'ui.common.country.egypt',
  'ui.common.country.el-salvador',
  'ui.common.country.estonia',
  'ui.common.country.faroe-islands',
  'ui.common.country.finland',
  'ui.common.country.france',
  'ui.common.country.germany',
  'ui.common.country.greece',
  'ui.common.country.greenland',
  'ui.common.country.guatemala',
  'ui.common.country.honduras',
  'ui.common.country.hong-kong',
  'ui.common.country.hungary',
  'ui.common.country.iceland',
  'ui.common.country.india',
  'ui.common.country.indonesia',
  'ui.common.country.iran-islamic-republic-of',
  'ui.common.country.iraq',
  'ui.common.country.ireland',
  'ui.common.country.israel',
  'ui.common.country.italy',
  'ui.common.country.jamaica',
  'ui.common.country.japan',
  'ui.common.country.jordan',
  'ui.common.country.kazakhstan',
  'ui.common.country.kenya',
  'ui.common.country.korea-republic-of',
  'ui.common.country.kuwait',
  'ui.common.country.kyrgyzstan',
  'ui.common.country.latvia',
  'ui.common.country.lebanon',
  'ui.common.country.libyan-arab-jamahiriya',
  'ui.common.country.liechtenstein',
  'ui.common.country.lithuania',
  'ui.common.country.luxembourg',
  'ui.common.country.macao',
  'ui.common.country.macedonia',
  'ui.common.country.malaysia',
  'ui.common.country.malta',
  'ui.common.country.mexico',
  'ui.common.country.monaco',
  'ui.common.country.mongolia',
  'ui.common.country.morocco',
  'ui.common.country.netherlands',
  'ui.common.country.new-zealand',
  'ui.common.country.nicaragua',
  'ui.common.country.nigeria',
  'ui.common.country.norway',
  'ui.common.country.oman',
  'ui.common.country.pakistan',
  'ui.common.country.panama',
  'ui.common.country.paraguay',
  'ui.common.country.peru',
  'ui.common.country.philippines',
  'ui.common.country.poland',
  'ui.common.country.portugal',
  'ui.common.country.puerto-rico',
  'ui.common.country.qatar',
  'ui.common.country.romania',
  'ui.common.country.russian-federa.',
  'ui.common.country.rwanda',
  'ui.common.country.saudi-arabia',
  'ui.common.country.senegal',
  'ui.common.country.serbia-and-montenegro',
  'ui.common.country.singapore',
  'ui.common.country.slovakia',
  'ui.common.country.slovenia',
  'ui.common.country.south-africa',
  'ui.common.country.spain',
  'ui.common.country.sweden',
  'ui.common.country.switzerland',
  'ui.common.country.syrian-arab-republic',
  'ui.common.country.taiwan',
  'ui.common.country.tajikistan',
  'ui.common.country.thailand',
  'ui.common.country.trinidad-and-tobago',
  'ui.common.country.tunisia',
  'ui.common.country.turkey',
  'ui.common.country.turkmenistan',
  'ui.common.country.ukraine',
  'ui.common.country.united-arab-emirates',
  'ui.common.country.united-kingdom',
  'ui.common.country.united-states',
  'ui.common.country.uruguay',
  'ui.common.country.uzbekistan',
  'ui.common.country.venezuela',
  'ui.common.country.vietnam',
  'ui.common.country.yemen',
  'ui.common.country.zimbabwe',
  'ui.common.country.other',
];

export const AGES: string[] = [
  'ui.io.demographics.age.rather_not_say',
  'ui.io.demographics.age.under_25',
  'ui.io.demographics.age.26-35',
  'ui.io.demographics.age.36-45',
  'ui.io.demographics.age.46-60',
  'ui.io.demographics.age.61_and_over',
];

export const LENGTHS_OF_SERVICE: string[] = [
  'ui.io.demographics.job.length_of_service.rather_not_say',
  'ui.io.demographics.job.length_of_service.less_than_1_year',
  'ui.io.demographics.job.length_of_service.1-2_years',
  'ui.io.demographics.job.length_of_service.3-5_years',
  'ui.io.demographics.job.length_of_service.6-9_years',
  'ui.io.demographics.job.length_of_service.10-14_years',
  'ui.io.demographics.job.length_of_service.more_than_14_years',
];

export const JOB_LEVELS: string[] = [
  'ui.io.demographics.job.level_of_position.rather_not_say',
  'ui.io.demographics.job.level_of_position.student',
  'ui.io.demographics.job.level_of_position.staff',
  'ui.io.demographics.job.level_of_position.associate',
  'ui.io.demographics.job.level_of_position.manager',
  'ui.io.demographics.job.level_of_position.sales_manager',
  'ui.io.demographics.job.level_of_position.dept_lead',
  'ui.io.demographics.job.level_of_position.area_manager',
  'ui.io.demographics.job.level_of_position.senior_manager',
  'ui.io.demographics.job.level_of_position.director',
  'ui.io.demographics.job.level_of_position.executive',
  'ui.io.demographics.job.level_of_position.president',
  'ui.io.demographics.job.level_of_position.other',
];

export const INDUSTRY_SECTORS: IndustrySectors = {
  'ui.io.demographics.job.sector.rather_not_say': {
    subSectors: ['ui.io.demographics.job.subsector.rather_not_say'],
  },
  'ui.io.demographics.job.sector.automobiles_and_components.name': {
    subSectors: [
      'ui.io.demographics.job.sector.automobiles_and_components.auto_parts_and_equipment',
      'ui.io.demographics.job.sector.automobiles_and_components.tires_and_rubber',
      'ui.io.demographics.job.sector.automobiles_and_components.automobile_manufacturers',
      'ui.io.demographics.job.sector.automobiles_and_components.motorcycle_manufacturers',
    ],
  },
  'ui.io.demographics.job.sector.banks.name': {
    subSectors: [
      'ui.io.demographics.job.sector.banks.diversified_banks',
      'ui.io.demographics.job.sector.banks.regional_banks',
      'ui.io.demographics.job.sector.banks.thrifts_and_mortgage_finance',
    ],
  },
  'ui.io.demographics.job.sector.capital_goods.name': {
    subSectors: [
      'ui.io.demographics.job.sector.capital_goods.aerospace_and_defense',
      'ui.io.demographics.job.sector.capital_goods.building_products',
      'ui.io.demographics.job.sector.capital_goods.construction_and_engineering',
      'ui.io.demographics.job.sector.capital_goods.electrical_components_and_equipment',
      'ui.io.demographics.job.sector.capital_goods.heavy_electrical_equipment',
      'ui.io.demographics.job.sector.capital_goods.industrial_conglomerates',
      'ui.io.demographics.job.sector.capital_goods.construction_machinery_and_heavy_trucks',
      'ui.io.demographics.job.sector.capital_goods.agricultural_and_farm_machinery',
      'ui.io.demographics.job.sector.capital_goods.industrial_machinery',
      'ui.io.demographics.job.sector.capital_goods.trading_companies_and_distributors',
    ],
  },
  'ui.io.demographics.job.sector.consumer_and_professional_services.name': {
    subSectors: [
      'ui.io.demographics.job.sector.consumer_and_professional_services.commercial_printing',
      'ui.io.demographics.job.sector.consumer_and_professional_services.environmental_and_facilities_services',
      'ui.io.demographics.job.sector.consumer_and_professional_services.office_services_and_supplies',
      'ui.io.demographics.job.sector.consumer_and_professional_services.diversified_support_services',
      'ui.io.demographics.job.sector.consumer_and_professional_services.security_and_alarm_services',
      'ui.io.demographics.job.sector.consumer_and_professional_services.human_resource_and_employment_services',
      'ui.io.demographics.job.sector.consumer_and_professional_services.research_and_consulting_services',
    ],
  },
  'ui.io.demographics.job.sector.consumer_durable_and_apparel.name': {
    subSectors: [
      'ui.io.demographics.job.sector.consumer_durable_and_apparel.consumer_electronics',
      'ui.io.demographics.job.sector.consumer_durable_and_apparel.home_furnishings',
      'ui.io.demographics.job.sector.consumer_durable_and_apparel.residential_construction',
      'ui.io.demographics.job.sector.consumer_durable_and_apparel.household_appliances',
      'ui.io.demographics.job.sector.consumer_durable_and_apparel.housewares_and_specialties',
      'ui.io.demographics.job.sector.consumer_durable_and_apparel.leisure_products',
      'ui.io.demographics.job.sector.consumer_durable_and_apparel.apparel_accessories_and_luxury_goods',
      'ui.io.demographics.job.sector.consumer_durable_and_apparel.footwear_manufacturers',
      'ui.io.demographics.job.sector.consumer_durable_and_apparel.textile_manufacturers',
    ],
  },
  'ui.io.demographics.job.sector.consumer_services.name': {
    subSectors: [
      'ui.io.demographics.job.sector.consumer_services.casinos_and_gaming',
      'ui.io.demographics.job.sector.consumer_services.hotels_resorts_and_cruise_lines',
      'ui.io.demographics.job.sector.consumer_services.leisure_facilities',
      'ui.io.demographics.job.sector.consumer_services.restaurants',
      'ui.io.demographics.job.sector.consumer_services.education_services',
      'ui.io.demographics.job.sector.consumer_services.specialized_consumer_services',
    ],
  },
  'ui.io.demographics.job.sector.diversified_financials.name': {
    subSectors: [
      'ui.io.demographics.job.sector.diversified_financials.other_diversified_financial_services',
      'ui.io.demographics.job.sector.diversified_financials.multi_sector_holdings',
      'ui.io.demographics.job.sector.diversified_financials.specialized_finance',
      'ui.io.demographics.job.sector.diversified_financials.consumer_finance',
      'ui.io.demographics.job.sector.diversified_financials.asset_management_and_custody_banks',
      'ui.io.demographics.job.sector.diversified_financials.investment_banking_and_brokerage',
      'ui.io.demographics.job.sector.diversified_financials.diversified_capital_markets',
    ],
  },
  'ui.io.demographics.job.sector.energy.name': {
    subSectors: [
      'ui.io.demographics.job.sector.energy.oil_and_gas_drilling',
      'ui.io.demographics.job.sector.energy.oil_and_gas_equipment_and_services',
      'ui.io.demographics.job.sector.energy.integrated_oil_and_gas',
      'ui.io.demographics.job.sector.energy.oil_and_gas_exploration_and_production',
      'ui.io.demographics.job.sector.energy.oil_and_gas_refining_and_marketing',
      'ui.io.demographics.job.sector.energy.oil_and_gas_storage_and_transportation',
      'ui.io.demographics.job.sector.energy.coal_and_consumable_fuels',
    ],
  },
  'ui.io.demographics.job.sector.food_and_staples_retailing.name': {
    subSectors: [
      'ui.io.demographics.job.sector.food_and_staples_retailing.drug_retail',
      'ui.io.demographics.job.sector.food_and_staples_retailing.food_distributors',
      'ui.io.demographics.job.sector.food_and_staples_retailing.food_retail',
      'ui.io.demographics.job.sector.food_and_staples_retailing.hypermarkets_and_super_centers',
    ],
  },
  'ui.io.demographics.job.sector.food_beverage_tobacco.name': {
    subSectors: [
      'ui.io.demographics.job.sector.food_beverage_tobacco.brewers',
      'ui.io.demographics.job.sector.food_beverage_tobacco.distillers_and_vintners',
      'ui.io.demographics.job.sector.food_beverage_tobacco.soft_drinks',
      'ui.io.demographics.job.sector.food_beverage_tobacco.agricultural_products',
      'ui.io.demographics.job.sector.food_beverage_tobacco.packaged_foods_and_meats',
      'ui.io.demographics.job.sector.food_beverage_tobacco.tobacco',
    ],
  },
  'ui.io.demographics.job.sector.health_care.name': {
    subSectors: [
      'ui.io.demographics.job.sector.health_care.health_care_equipment',
      'ui.io.demographics.job.sector.health_care.health_care_supplies',
      'ui.io.demographics.job.sector.health_care.health_care_distributors',
      'ui.io.demographics.job.sector.health_care.health_care_services',
      'ui.io.demographics.job.sector.health_care.health_care_facilities',
      'ui.io.demographics.job.sector.health_care.managed_health_care',
      'ui.io.demographics.job.sector.health_care.health_care_technology',
    ],
  },
  'ui.io.demographics.job.sector.household_and_personal_products.name': {
    subSectors: [
      'ui.io.demographics.job.sector.household_and_personal_products.household_products',
      'ui.io.demographics.job.sector.household_and_personal_products.personal_products',
    ],
  },
  'ui.io.demographics.job.sector.insurance.name': {
    subSectors: [
      'ui.io.demographics.job.sector.insurance.insurance_brokers',
      'ui.io.demographics.job.sector.insurance.life_and_health_insurance',
      'ui.io.demographics.job.sector.insurance.multi_line_insurance',
      'ui.io.demographics.job.sector.insurance.property_and_casualty_insurance',
      'ui.io.demographics.job.sector.insurance.reinsurance',
    ],
  },
  'ui.io.demographics.job.sector.materials.name': {
    subSectors: [
      'ui.io.demographics.job.sector.materials.commodity_chemicals',
      'ui.io.demographics.job.sector.materials.diversified_chemicals',
      'ui.io.demographics.job.sector.materials.fertilizers_and_agricultural_chemicals',
      'ui.io.demographics.job.sector.materials.industrial_gases',
      'ui.io.demographics.job.sector.materials.specialty_chemicals',
      'ui.io.demographics.job.sector.materials.construction_materials',
      'ui.io.demographics.job.sector.materials.metal_and_glass_containers',
      'ui.io.demographics.job.sector.materials.paper_packaging',
      'ui.io.demographics.job.sector.materials.aluminum',
      'ui.io.demographics.job.sector.materials.diversified_metals_and_mining',
      'ui.io.demographics.job.sector.materials.gold',
      'ui.io.demographics.job.sector.materials.precious_metals_and_minerals',
      'ui.io.demographics.job.sector.materials.silver',
      'ui.io.demographics.job.sector.materials.steel',
      'ui.io.demographics.job.sector.materials.forest_products',
      'ui.io.demographics.job.sector.materials.paper_products',
    ],
  },
  'ui.io.demographics.job.sector.media.name': {
    subSectors: [
      'ui.io.demographics.job.sector.media.advertising',
      'ui.io.demographics.job.sector.media.broadcasting',
      'ui.io.demographics.job.sector.media.cable_and_satellite',
      'ui.io.demographics.job.sector.media.movies_and_entertainment',
      'ui.io.demographics.job.sector.media.publishing',
    ],
  },
  'ui.io.demographics.job.sector.pharma_biotech_and_life_sciences.name': {
    subSectors: [
      'ui.io.demographics.job.sector.pharma_biotech_and_life_sciences.biotechnology',
      'ui.io.demographics.job.sector.pharma_biotech_and_life_sciences.pharmaceuticals',
      'ui.io.demographics.job.sector.pharma_biotech_and_life_sciences.life_sciences_tools_and_services',
    ],
  },
  'ui.io.demographics.job.sector.real_estate_and_investment_trusts.name': {
    subSectors: [
      'ui.io.demographics.job.sector.real_estate_and_investment_trusts.diversified_reits',
      'ui.io.demographics.job.sector.real_estate_and_investment_trusts.industrial_reits',
      'ui.io.demographics.job.sector.real_estate_and_investment_trusts.mortgage_reits',
      'ui.io.demographics.job.sector.real_estate_and_investment_trusts.hotel_and_resort_reits',
      'ui.io.demographics.job.sector.real_estate_and_investment_trusts.office_reits',
      'ui.io.demographics.job.sector.real_estate_and_investment_trusts.health_care_reits',
      'ui.io.demographics.job.sector.real_estate_and_investment_trusts.residential_reits',
      'ui.io.demographics.job.sector.real_estate_and_investment_trusts.specialized_reits',
      'ui.io.demographics.job.sector.real_estate_and_investment_trusts.retail_reits',
      'ui.io.demographics.job.sector.real_estate_and_investment_trusts.diversified_real_estate_activities',
      'ui.io.demographics.job.sector.real_estate_and_investment_trusts.real_estate_development',
      'ui.io.demographics.job.sector.real_estate_and_investment_trusts.real_estate_operating_companies',
      'ui.io.demographics.job.sector.real_estate_and_investment_trusts.real_estate_services',
    ],
  },
  'ui.io.demographics.job.sector.retailing.name': {
    subSectors: [
      'ui.io.demographics.job.sector.retailing.retail_distributors',
      'ui.io.demographics.job.sector.retailing.catalog_retail',
      'ui.io.demographics.job.sector.retailing.internet_retail',
      'ui.io.demographics.job.sector.retailing.department_stores',
      'ui.io.demographics.job.sector.retailing.general_merchandise_stores',
      'ui.io.demographics.job.sector.retailing.apparel_retail',
      'ui.io.demographics.job.sector.retailing.computer_and_electronics_retail',
      'ui.io.demographics.job.sector.retailing.home_improvement_retail',
      'ui.io.demographics.job.sector.retailing.specialty_stores',
      'ui.io.demographics.job.sector.retailing.automotive_retail',
      'ui.io.demographics.job.sector.retailing.home_furnishing_retail',
    ],
  },
  'ui.io.demographics.job.sector.semiconductors_and_equip.name': {
    subSectors: [
      'ui.io.demographics.job.sector.semiconductors_and_equip.semiconductors',
      'ui.io.demographics.job.sector.semiconductors_and_equip.semiconductor_equipment',
    ],
  },
  'ui.io.demographics.job.sector.software_and_IT_services.name': {
    subSectors: [
      'ui.io.demographics.job.sector.software_and_IT_services.internet_software_and_services',
      'ui.io.demographics.job.sector.software_and_IT_services.it_consulting_and_other_services',
      'ui.io.demographics.job.sector.software_and_IT_services.application_software',
      'ui.io.demographics.job.sector.software_and_IT_services.systems_software',
      'ui.io.demographics.job.sector.software_and_IT_services.home_entertainment_software',
    ],
  },
  'ui.io.demographics.job.sector.technology_hardware_and_equipment.name': {
    subSectors: [
      'ui.io.demographics.job.sector.technology_hardware_and_equipment.communications_equipment',
      'ui.io.demographics.job.sector.technology_hardware_and_equipment.technology_hardware_storage_and_peripherals',
      'ui.io.demographics.job.sector.technology_hardware_and_equipment.electronic_equipment_and_instruments',
      'ui.io.demographics.job.sector.technology_hardware_and_equipment.electronic_components',
      'ui.io.demographics.job.sector.technology_hardware_and_equipment.electronic_manufacturing_services',
      'ui.io.demographics.job.sector.technology_hardware_and_equipment.technology_distributors',
    ],
  },
  'ui.io.demographics.job.sector.telecommunication_services.name': {
    subSectors: [
      'ui.io.demographics.job.sector.telecommunication_services.alternative_carriers',
      'ui.io.demographics.job.sector.telecommunication_services.integrated_telecommunication_services',
      'ui.io.demographics.job.sector.telecommunication_services.wireless_telecommunication_services',
    ],
  },
  'ui.io.demographics.job.sector.transportation.name': {
    subSectors: [
      'ui.io.demographics.job.sector.transportation.air_freight_and_logistics',
      'ui.io.demographics.job.sector.transportation.airlines',
      'ui.io.demographics.job.sector.transportation.marine',
      'ui.io.demographics.job.sector.transportation.railroads',
      'ui.io.demographics.job.sector.transportation.trucking',
      'ui.io.demographics.job.sector.transportation.airport_services',
      'ui.io.demographics.job.sector.transportation.highways_and_railtracks',
      'ui.io.demographics.job.sector.transportation.marine_ports_and_services',
    ],
  },
  'ui.io.demographics.job.sector.utilities.name': {
    subSectors: [
      'ui.io.demographics.job.sector.utilities.electric_utilities',
      'ui.io.demographics.job.sector.utilities.gas_utilities',
      'ui.io.demographics.job.sector.utilities.multi_utilities',
      'ui.io.demographics.job.sector.utilities.water_utilities',
      'ui.io.demographics.job.sector.utilities.independent_power_producers_and_energy_traders',
    ],
  },
  'ui.io.demographics.job.sector.other.name': {
    subSectors: [
      'ui.io.demographics.job.sector.other.not_for_profit',
      'ui.io.demographics.job.sector.other.student',
      'ui.io.demographics.job.sector.other.other',
    ],
  },
};

export const DATADOG_RUM_CONFIGS: DatadogRumConfigs[] = [
  {
    hostname: 'test.experience.insights.com',
    applicationId: 'd9edb65b-af8e-459e-8dd9-8d6434b00f4a',
    clientToken: 'pubafe2d24f6e87860fc91b4f53a43f4185',
  },
  {
    hostname: 'demo.experience.insights.com',
    applicationId: '7b510961-7b5f-427d-a205-32664c690aef',
    clientToken: 'pub2dd86c7a4eabdaadea2ed3d196a4107f',
  },
  {
    hostname: 'experience.insights.com',
    applicationId: 'ccc9f3d3-4557-46b7-8bb4-5f74fe4ae54b',
    clientToken: 'pub55c6d8b91bc578d1d215dad0301403b3',
  },
];

export const VALID_EMAIL_REGEX = /^[!-~]+@\S+\.\S+$/;

export const colourMap: ColourMapProp = {
  coolBlue: 'blue',
  earthGreen: 'green',
  sunshineYellow: 'yellow',
  fieryRed: 'red',
};

export const colourMapInverted: Record<Colour, ColourEnergy> = {
  blue: 'coolBlue',
  green: 'earthGreen',
  yellow: 'sunshineYellow',
  red: 'fieryRed',
};

export const EVALUATOR_TITLE_KEYS: Record<Evaluator, string> = {
  DDDRS: 'ui.io.discovery.evaluator_title',
  DISCOVERY: 'ui.io.discovery.evaluator_title',
  DFC: 'ui.io.dfc.evaluator_title',
  EXPLORE: 'ui.io.discovery.evaluator_title',
};

export const PAGE_TITLE_KEYS: Record<PageTitle, string> = {
  WELCOME: 'ui.io.onboarding.welcome_title',
  YOUR_RELATIONSHIP: 'ui.io.evaluator.dfc.choice_question.title',
  QUESTION: 'ui.io.evaluator.progress_text',
  THANK_YOU: 'ui.io.evaluator.complete.page_title',
  INSTRUCTIONS: 'ui.io.evaluator.instructions.info_title',
  ABOUT_YOU: 'ui.io.about_you.page_title',
  ERROR_ROUTE: 'ui.io.invite.error.generic_error',
  ERROR_TOKEN: 'ui.io.error.invite_expired.title',
  CONFIRM_EMAIL: 'ui.io.confirm_email.email_form.heading',
  DIGITAL_PROFILE: 'ui.io.explore.evaluator.digital_profile.title',
};
